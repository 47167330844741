import { useCallback } from 'react';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';

interface PrintComponent {
  id: string;
  width?: number;
  height?: number;
}

export const usePrintToPdf = () => {
  // function to get the dimensions of the image
  const getImageDimensions = useCallback((dataUrl: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  }, []);

  // principal function to print to PDF
  const printToPdf = useCallback(
    async (components: PrintComponent[], fileName: string) => {
      const pdf = new jsPDF('p', 'mm', 'a4'); // crear documento PDF A4
      const margin = 10; // PDF margins
      const pageWidth = pdf.internal.pageSize.getWidth() - 2 * margin; // Width of the page minus margins
      let currentY = margin; // position Y for the first image

      for (const component of components) {
        const element = document.getElementById(component.id);
        if (element) {
          // capture the element as a PNG image
          const dataUrl = await toPng(element, { quality: 1, pixelRatio: 2 });

          // get image dimensions
          const imgProps = await getImageDimensions(dataUrl);
          const imgWidth = component.width ?? pageWidth;
          const imgHeight = component.height ?? (imgProps.height * imgWidth) / imgProps.width;

          // verify if the image fits on the current page
          if (currentY + imgHeight > pdf.internal.pageSize.getHeight() - margin) {
            // If it doesn't fit, add a new page
            pdf.addPage();
            currentY = margin; // restart Y position in the new page
          }

          // add the image to the PDF at the current position
          pdf.addImage(dataUrl, 'PNG', margin, currentY, imgWidth, imgHeight);

          // update Y position for the next image
          currentY += imgHeight + margin; // add some space between images
        }
      }

      // save the PDF with the specified file name
      pdf.save(`${fileName}.pdf`);
    },
    [getImageDimensions]
  );

  return { printToPdf };
};
