import { useFormContext, useWatch } from 'react-hook-form';
import { Input } from 'react-daisyui';
import { CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { Row } from '@components/tables/types';
import { additiveDegBeMap } from './constants';
import { fluidWidthStockPerc } from './acid-brine-helpers';
import { useEffect } from 'react';

export const StockAcidCell = <T extends Row>({ row: { index, original }, column }: CellProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  const { register, setValue } = useFormContext();
  const namePrefix = `${rowsPropertyName}.${index}`;
  const additiveTypeValue = useWatch({ name: `${namePrefix}.additiveType`, defaultValue: '' });
  const additiveValue = useWatch({ name: `${namePrefix}.additive`, defaultValue: '' });

  const isDisabled =
    (additiveTypeValue !== 'acid' && additiveTypeValue !== 'salt') ||
    Object.keys(additiveDegBeMap).includes(additiveValue) ||
    !fluidWidthStockPerc.includes(additiveValue.toLowerCase());

  useEffect(() => {
    if (isDisabled) setValue(`${namePrefix}.${column.id}`, '');
  }, [additiveTypeValue, additiveValue, column.id, namePrefix, setValue, isDisabled]);

  return (
    <div className="relative w-full">
      <Input
        className="input input-sm input-bordered w-full"
        disabled={isDisabled}
        key={original.id}
        {...register(`${namePrefix}.${column.id}`)}
      />
      <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-500 pointer-events-none">%</span>
    </div>
  );
};
