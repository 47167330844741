import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-daisyui';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormInput, schema } from './schema';
import ExperimentInformation from './components/experiment-information';
import CouponInformation from './components/coupon-information';
import AcidBlendRecipe from './components/acid-blend-recipe';
import Results from './components/results';
import Footer from './components/footer';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { shapes } from './dummyData';
import {
  useGetApiV1CatalogsDropdownsTypeid,
  usePostApiV1ExperimentsCorrosion,
  usePutApiV1ExperimentsCorrosionExperimentidResultsImage,
} from '@sdk-client/hooks';
import { CreateCorrosionExperimentSch } from '@sdk-client/models';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { useNavigate } from 'react-router';
import { AdditionRow, ImageType } from './components/types';
import { CatalogType } from '@pages/Admin/DropDownListData/types';
import { SelectAutocompleteOption } from '@components/form/SelectAutocomplete';
import { WorkSheetModal } from './components/WorkSheetModal';

type CorrosionCreateProps = {};

export const CorrosionCreate = (props: CorrosionCreateProps) => {
  const navigate = useNavigate();
  const dateInitialValue = useMemo(() => {
    return {
      startDate: null,
      endDate: null,
    };
  }, []);
  const dateState = useState<DateValueType>(dateInitialValue);
  const projectIdState = useState<SelectAutocompleteOption | null>(null);
  const projectNameState = useState<SelectAutocompleteOption | null>(null);
  const [experimentId, setExperimentId] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [imagesSubmitted, setImagesSubmitted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const printReportModalRef = useRef<HTMLDialogElement>(null);

  const methods = useForm<FormInput>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const { getValues, setValue } = methods;

  const { mutateAsync: mutateFormAsync, isPending: isPendingForm } = usePostApiV1ExperimentsCorrosion({
    mutation: {
      onSuccess: (responseData, postedData) => {
        setExperimentId(responseData.id);
        setFormSubmitted(true);
      },
    },
  });

  const { mutateAsync: resultsImageMutateAsync } = usePutApiV1ExperimentsCorrosionExperimentidResultsImage(
    experimentId,
    {
      client: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    }
  );

  const { data: equipments, isPending: isPendingEquipments } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.EQUIPMENTS)
  );
  const { data: cellNumbers, isPending: isPendingCellNumbers } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.CELL_NUMBERS)
  );
  const { data: metallurgies, isPending: isPendingMetallurgies } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.METALLURGIES)
  );
  const { data: pittingIndexSource, isPending: isPendingpittingIndexSource } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.PITTING_INDEX_SOURCE)
  );
  const { data: locationsSource, isPending: isPendingLocationsSource } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.LOCATION)
  );

  const handleImageUpload = async (imageData: ImageType, userId: string): Promise<void> => {
    if (imageData.image && (imageData.image as Blob)?.type) {
      return resultsImageMutateAsync({
        type: imageData.type,
        userId,
        image: imageData.image as string,
      }).catch((error) => {
        console.error(`Error uploading image of type ${imageData.type}:`, error);
      });
    }
  };

  const uploadImages = async (data: FormInput, userId: string): Promise<void> => {
    const imageTypes: ImageType[] = [
      { image: data?.results?.frontPreTestImg, type: 'FrontPreTest' },
      { image: data?.results?.frontPostTestImg, type: 'FrontPostTest' },
      { image: data?.results?.backPreTestImg, type: 'BackPreTest' },
      { image: data?.results?.backPostTestImg, type: 'BackPostTest' },
    ];

    for (const imageData of imageTypes) {
      await handleImageUpload(imageData, userId);
    }
  };

  const defaultRow: AdditionRow = {
    id: '0',
    physicalState: '',
    orderOfAddition: '',
    additiveType: 'solvent',
    additive: 'water',
    stockAcid: '',
    lotNumber: '',
    concentration: '',
    volMass: '',
    solidVolMass: '',
  };

  useEffect(() => {
    setValue('acidBlendAdditions', [defaultRow]);
  }, []);

  useEffect(() => {
    const submitImages = async () => {
      const formInputs = getValues();
      try {
        await uploadImages(formInputs, '1'); // TODO: get userId from auth context
        setImagesSubmitted(true);
        if (isSaved) {
          navigate(`/experiments/corrosion/update/${experimentId}`);
          return;
        }
        navigate('/experiments/corrosion');
      } catch (error) {
        console.error('Error uploading images:', error);
      }
    };
    if (formSubmitted && !imagesSubmitted) {
      submitImages();
    }
  }, [formSubmitted, imagesSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit: SubmitHandler<FormInput> = async (data, e) => {
    const nativeEvent = e?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter;
    setIsSaved(submitter?.id === 'save');
    const body: CreateCorrosionExperimentSch = {
      date: data.date || undefined,
      operator: data.operator,
      projectID: data.projectID,
      equipment: data.equipment,
      location: data.location,
      customer: data.customer,
      projectName: data.projectName,
      testId: data.testId,
      couponId: data.couponId,
      h2sPerc: data.h2sPerc,
      purpose: data.purpose,
      cellNumber: data.cellNumber,
      temperatureDegF: data.temperatureDegF,
      tempRampUpTime: data.tempRampUpTime,
      pressure: data.pressure,
      incubationTime: data.incubationTime,
      metallurgy: data.metallurgy,
      metallurgyDensity: data.metallurgyDensity,
      initialWeight: data.initialWeight,
      shape: data.shape,
      sampleVolumeMl: data.sampleVolumeMl,
      acidBlendTemperatureDegC: data.acidBlendTemperatureDegC,
      acidBlendAdditions: data.acidBlendAdditions,
      hollowCylinder: data.hollowCylinder,
      cylinder: data.cylinder,
      curvedSampleNoHole: data.curvedSampleNoHole,
      curvedSampleWithHole: data.curvedSampleWithHole,
      flatRectangularSquareNoHole: data.flatRectangularSquareNoHole,
      flatRectangularSquareWithHole: data.flatRectangularSquareWithHole,
      customShape: data.customShape,
      results: data.results && {
        initialWeight: data.initialWeight,
        finalWeight: data.results.finalWeight,
        weightLoss: data.results.weightLoss ?? 0,
        corrosionLoss: data.results.corrosionLoss ?? 0,
        corrosionRateMpy: data.results.corrosionRateMpy ?? 0,
        corrosionRateMmpy: data.results.corrosionRateMmpy ?? 0,
        pittingIndex: data.results?.pittingIndex ?? '',
        observations: data.results?.observations ?? '',
      },
    };
    await mutateFormAsync(body);
  };
  const formReset = () => {
    methods.reset();
    navigate('/experiments/corrosion');
  };
  const formSave = () => {
    console.log('Form values: ', methods.getValues());
    console.log('Form Errors: ', methods.formState.errors);
  };

  const handlePrint = () => {
    printReportModalRef.current?.showModal();
  };

  if (
    isPendingForm ||
    isPendingEquipments ||
    isPendingCellNumbers ||
    isPendingMetallurgies ||
    isPendingpittingIndexSource ||
    isPendingLocationsSource
  )
    return <LoadingRing />;

  return (
    <div className="flex flex-wrap gap-2">
      <FormProvider {...methods}>
        <Form
          className="shadow shadow-gray-600 bg-base-200 w-full rounded-lg p-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Footer
            reset={formReset}
            save={formSave}
            onPrint={handlePrint}
          />
          <ExperimentInformation
            openDefault={true}
            dateState={dateState}
            projectIdState={projectIdState}
            projectNameState={projectNameState}
            equipments={equipments ?? []}
            cellNumbers={cellNumbers ?? []}
            locations={locationsSource ?? []}
          />
          <CouponInformation
            openDefault={true}
            metallurgies={metallurgies ?? []}
            shapes={shapes}
          />
          <AcidBlendRecipe openDefault={true} />
          <Results
            openDefault={true}
            pittingIndexSource={pittingIndexSource ?? []}
          />
          <Footer
            reset={formReset}
            save={formSave}
            onPrint={handlePrint}
          />
          <Button
            title="back"
            size="sm"
            color="primary"
            type="submit"
            className="fixed top-36 right-5 z-50"
            startIcon={<ArrowUturnLeftIcon className="h-6 w-6" />}
          ></Button>
          <WorkSheetModal ref={printReportModalRef} />
        </Form>
      </FormProvider>
    </div>
  );
};
