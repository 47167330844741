import { Tabs } from 'react-daisyui';
import { TabProps } from './types';
import { TextArea, TextBox } from '@components/form';
import TabContent from './tab-content';
import { CONTENT_TAB_STYLE } from './constants';
import useFormContextExtended from './useFormContextExtended';
import clsx from 'clsx';

const TechnicalDetailsTab = ({ indexTab, activeTab, setActiveTab }: TabProps) => {
  const { extendRegister, errors } = useFormContextExtended();

  return (
    <Tabs.RadioTab
      checked={activeTab === indexTab}
      onChange={() => setActiveTab(indexTab)}
      name="section"
      label="Technical Details"
      contentClassName={clsx(CONTENT_TAB_STYLE)}
    >
      <TabContent>
        <div className="grow">
          <div className="w-fit">
            <TextBox
              label="Well Name"
              {...extendRegister('wellName')}
            />
            <TextBox
              label="Depth (ft)"
              {...extendRegister('depth')}
            />
            <TextBox
              label="Bottom Hole Temperature (°F)"
              {...extendRegister('bottomHoleTemperature')}
            />
            <TextBox
              label="Bottom Hole Pressure (psi)"
              {...extendRegister('bottomHolePressure')}
            />
          </div>
        </div>
        <div className="grow">
          <div className="justify-self-start w-[46vh]">
            <TextArea
              label="Type of Data Requested"
              className="h-[283px] rounded-badge"
              error={errors.typeOfDataRequested?.message}
              {...extendRegister('typeOfDataRequested')}
            />
          </div>
        </div>
        <div className="grow">
          <div className="justify-self-start w-[46vh]">
            <TextArea
              label="Fluid Details"
              className="h-[283px] rounded-badge"
              {...extendRegister('fluidDetails')}
            />
          </div>
        </div>
      </TabContent>
    </Tabs.RadioTab>
  );
};

export default TechnicalDetailsTab;
