import { RequestLocation } from './components/types';
import { RequestLocationCard } from './components/request-location-card';
import { HoustonTechCenter, puneTechCenter } from '@assets/images/ticketing-home';

interface RequestFormSelectionProps {
  onSelectionMade: (location: RequestLocation) => void;
}

export const RequestFormSelection = ({ onSelectionMade }: RequestFormSelectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex-grow flex justify-center items-center">
        <div className="flex space-x-4">
          <RequestLocationCard
            imageSrc={HoustonTechCenter}
            location="Houston"
            onSelectionMade={onSelectionMade}
            requestCardPrompt="Click “Create Request” below in order to initiate a project through Houston Tech Services."
          />
          <RequestLocationCard
            location="Pune"
            imageSrc={puneTechCenter}
            onSelectionMade={onSelectionMade}
            requestCardPrompt="Click “Create Request” below in order to initiate a project through Houston Tech Services."
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col text-center">
          <p>
            For general questions, please contact{' '}
            <a
              href="mailto:FHOUPETECHSVCS@Halliburton.com"
              className="text-primary hover:underline"
            >
              FHOUPETECHSVCS@Halliburton.com
            </a>
          </p>
          <p>Houston Shipping Address: 15081 1/2 Milner Rd, Gate 3, Houston, TX 77032</p>
          <p>Sai Radhe Building 100 + 101 Kennedy Road, Pune 411001 India</p>
        </div>
      </div>
    </div>
  );
};
