import { Authorization } from '@lib/Authorization';
import { Button } from 'react-daisyui';
import { DocumentDuplicateIcon, FolderPlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';

type FooterProps = {
  experimentId?: string;
  reset: () => void;
  save: () => void;
  onCopy?: () => void;
  onPrint?: () => void;
};

const Footer = ({ experimentId, reset, save, onCopy, onPrint }: FooterProps) => {
  const navigate = useNavigate();
  return (
    <Authorization allowedRoles={['Admin', 'Scientist/Technician']}>
      <div className="flex">
        <div className="flex justify-start gap-2 w-full">
          <Button
            size="xs"
            type="button"
            onClick={() => navigate('/experiments/corrosion/create')}
            title="Create new"
          >
            {<FolderPlusIcon className="h-5 w-5" />}
          </Button>
          <Button
            size="xs"
            type="button"
            disabled={!!!experimentId}
            onClick={() => {
              onCopy?.();
            }}
            title="Copy experiment"
          >
            {<DocumentDuplicateIcon className="h-5 w-5" />}
          </Button>
        </div>
        <div className="flex justify-end gap-2 w-full">
          <Button
            id="save"
            color="neutral"
            size="xs"
            type="submit"
            //onClick={() => save()}
          >
            Save
          </Button>
          <Button
            color="secondary"
            size="xs"
            type="submit"
          >
            Save & Close
          </Button>
          <Button
            color="secondary"
            size="xs"
            type="button"
            onClick={() => {
              onPrint?.();
            }}
          >
            Print
          </Button>
          <Button
            size="xs"
            color="primary"
            type="reset"
            onClick={() => reset()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Authorization>
  );
};

export default Footer;
