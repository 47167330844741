import { AcidBlendReportProps, DimensionsType } from './CorrosionReport';
import { FormInput, AdditionSchema, shapeEnum } from './schema';

/**
 * Convert the form input data into a report properties object for the corrosion report.
 * @param formInput Object containing all the necessary input data from the form.
 * @returns Fortmatted report properties for the corrosion report.
 */
export function mapFormInputToReportProps(formInput: FormInput): AcidBlendReportProps {
  // Auxiliary function to get dimensions based on the shape
  const getDimensions = (): DimensionsType => {
    const dimensions: DimensionsType = {
      surfaceArea: formInput.surfaceArea?.toString() || '',
    };

    switch (formInput.shape) {
      case shapeEnum.Enum.CurvedWithHole:
        if (formInput.curvedSampleWithHole) {
          dimensions.w1 = formInput.curvedSampleWithHole?.width1?.toString() || '';
          dimensions.w2 = formInput.curvedSampleWithHole?.width2?.toString() || '';
          dimensions.length = formInput.curvedSampleWithHole?.length?.toString() || '';
          dimensions.thickness = formInput.curvedSampleWithHole?.thickness?.toString() || '';
          dimensions.holeDiameter = formInput.curvedSampleWithHole?.holeDiameter?.toString() || '';
        }
        break;
      case shapeEnum.Enum.CurvedNoHole:
        if (formInput.curvedSampleNoHole) {
          dimensions.w1 = formInput.curvedSampleNoHole?.width1?.toString() || '';
          dimensions.w2 = formInput.curvedSampleNoHole?.width2?.toString() || '';
          dimensions.length = formInput.curvedSampleNoHole?.length?.toString() || '';
          dimensions.thickness = formInput.curvedSampleNoHole?.thickness?.toString() || '';
        }
        break;
      case shapeEnum.Enum.FlatWithHole:
        if (formInput.flatRectangularSquareWithHole) {
          dimensions.w1 = formInput.flatRectangularSquareWithHole?.width?.toString() || '';
          dimensions.length = formInput.flatRectangularSquareWithHole?.length?.toString() || '';
          dimensions.thickness = formInput.flatRectangularSquareWithHole?.thickness?.toString() || '';
          dimensions.holeDiameter = formInput.flatRectangularSquareWithHole?.holeDiameter?.toString() || '';
        }
        break;
      case shapeEnum.Enum.FlatNoHole:
        if (formInput.flatRectangularSquareNoHole) {
          dimensions.w1 = formInput.flatRectangularSquareNoHole?.width?.toString() || '';
          dimensions.length = formInput.flatRectangularSquareNoHole?.length?.toString() || '';
          dimensions.thickness = formInput.flatRectangularSquareNoHole?.thickness?.toString() || '';
        }
        break;
      case shapeEnum.Enum.CylinderWithHole:
        if (formInput.hollowCylinder) {
          dimensions.w1 = formInput.hollowCylinder?.diameter1?.toString() || '';
          dimensions.w2 = formInput.hollowCylinder?.diameter2?.toString() || '';
          dimensions.length = formInput.hollowCylinder?.length?.toString() || '';
        }
        break;
      case shapeEnum.Enum.CylinderNoHole:
        if (formInput.cylinder) {
          dimensions.w1 = formInput.cylinder?.diameter?.toString() || '';
          dimensions.length = formInput.cylinder?.length?.toString() || '';
        }
        break;
      case shapeEnum.Enum.Sphere:
        if (formInput.sphere) {
          dimensions.w1 = formInput.sphere?.diameter?.toString() || '';
        }
        break;
      case shapeEnum.Enum.FlatCircleWithHole:
        if (formInput.flatCircleWithHole) {
          dimensions.w1 = formInput.flatCircleWithHole?.diameter?.toString() || '';
          dimensions.thickness = formInput.flatCircleWithHole?.thickness?.toString() || '';
          dimensions.holeDiameter = formInput.flatCircleWithHole?.holeDiameter?.toString() || '';
        }
        break;
      case shapeEnum.Enum.FlatCircleNoHole:
        if (formInput.flatCircleNoHole) {
          dimensions.w1 = formInput.flatCircleNoHole?.diameter?.toString() || '';
          dimensions.thickness = formInput.flatCircleNoHole?.thickness?.toString() || '';
        }
        break;
      case shapeEnum.Enum.CustomShape:
        // for custom shapes, we only have the surface area
        break;
    }

    return dimensions;
  };

  // Maps the acid blend additions to the format required for the report.
  const mapAcidBlendAdditions = () => {
    if (!formInput.acidBlendAdditions || formInput.acidBlendAdditions.length === 0) {
      return [];
    }

    return formInput.acidBlendAdditions.map((addition: AdditionSchema, index: number) => ({
      order: index + 1,
      physicalState: addition.physicalState,
      additiveType: addition.additiveType,
      additive: addition.additive,
      concentration: addition.concentration,
      volumeMass: addition.volMass,
    }));
  };

  // determine the acid blend name based on the additives
  const getAcidBlendName = (): string => {
    if (!formInput.acidBlendAdditions || formInput.acidBlendAdditions.length === 0) {
      return 'N/A';
    }
    return formInput.acidBlendAdditions.map((addition) => addition.additive).join(', ');
  };

  // asure that couponInfo is always defined
  const couponInfo = {
    metallurgy: formInput.metallurgy || '',
    couponId: formInput.couponId || '',
    couponShape: formInput.shape || '',
    initialWeight: formInput?.initialWeight?.toString() || '',
    finalWeight: formInput?.results?.finalWeight?.toString() || '',
    weightLoss: (formInput?.results?.weightLoss || 0).toString() || '',
    corrosionLoss: (formInput?.results?.corrosionLoss || 0).toString() || '',
    dimensions: getDimensions() || '',
  };

  //build the report properties object with all the necessary information
  const reportProps: AcidBlendReportProps = {
    projectId: formInput.projectID,
    projectName: formInput.projectName,
    technician: formInput.operator,
    experimentInfo: {
      date: formInput.date,
      testId: formInput.testId,
      acidBlend: getAcidBlendName(),
      blendVolume: formInput?.sampleVolumeMl?.toString() || '',
      temperature: formInput?.temperatureDegF?.toString() || '',
      incubationTime: formInput?.incubationTime?.toString() || '',
      equipment: formInput?.equipment,
      cellNumber: formInput?.cellNumber,
      timeTestStarted: '',
    },
    acidBlendRecipe: mapAcidBlendAdditions(),
    couponInfo: couponInfo,
    notes: formInput?.results?.observations || '',
  };

  return reportProps;
}
