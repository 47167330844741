import { cldLandingPage } from '@assets/images/landing';
import { Button, Card, Divider } from 'react-daisyui';
import { useNavigate } from 'react-router';

type LandingProps = {};

export const Landing = ({}: LandingProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col align-middle overflow-hidden">
      <img
        className="h-[75vh] object-cover"
        src={cldLandingPage}
      />
      <div className="m-3 w-full h-15 flex justify-center gap-10">
        <Card className="grid w-1/4 rounded-box place-items-center">
          <Button
            type="button"
            color="primary"
            className="w-full h-full text-lg"
            onClick={() => navigate('/ticketing/request')}
          >
            Technical Services
          </Button>
        </Card>
        <Divider horizontal={true} />
        <Card className="grid w-1/4 rounded-box place-items-center">
          <Button
            type="button"
            color="primary"
            className="w-full h-full text-lg"
            onClick={() => navigate('test-methods')}
          >
            R & D
          </Button>
        </Card>
      </div>
    </div>
  );
};
