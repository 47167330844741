import { forwardRef } from 'react';
import { Radio } from 'react-daisyui';

type Props = {
  label?: React.ReactNode;
  error?: string;
  is_required?: string;
  onChange1: () => void;
  onChange2: () => void;
  checked1: boolean;
  checked2: boolean;
  ref?: React.Ref<HTMLInputElement>;
};

export const FormPairRadios = forwardRef<HTMLDivElement, Props>(
  ({ label, error, is_required, checked1, checked2, onChange1, onChange2, ...restProps }, ref) => {
    return (
      <div className="flex w-full component-preview pb-6 items-center justify-center gap-2 font-sans">
        <div className="form-control w-28 xs:w-40 md:w-full max-w-xs">
          <label className="label">
            <span className="label-text">
              {label}
              {is_required && <span className="label-text text-red-400"> * </span>}
            </span>
            {error && <span className="label-text-alt text-red-400">{error}</span>}
          </label>
          <div
            className="flex items-center gap-6"
            ref={ref}
          >
            <div className="flex items-center gap-2">
              <Radio
                size="sm"
                checked={checked1}
                onChange={() => onChange1()}
                {...restProps}
              />
              <span className="label-text">Yes</span>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                size="sm"
                checked={checked2}
                onChange={() => onChange2()}
                {...restProps}
              />
              <span className="label-text">No</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
