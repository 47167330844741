import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PostApiV1ExperimentsCorrosionListCompleteMutationRequest, PostApiV1ExperimentsCorrosionListCompleteMutationResponse, PostApiV1ExperimentsCorrosionListComplete400, PostApiV1ExperimentsCorrosionListComplete401, PostApiV1ExperimentsCorrosionListComplete403, PostApiV1ExperimentsCorrosionListComplete404 } from "../models/PostApiV1ExperimentsCorrosionListComplete";
import type { UseMutationOptions } from "@tanstack/react-query";

type PostApiV1ExperimentsCorrosionListCompleteClient = typeof client<PostApiV1ExperimentsCorrosionListCompleteMutationResponse, PostApiV1ExperimentsCorrosionListComplete400 | PostApiV1ExperimentsCorrosionListComplete401 | PostApiV1ExperimentsCorrosionListComplete403 | PostApiV1ExperimentsCorrosionListComplete404, PostApiV1ExperimentsCorrosionListCompleteMutationRequest>;
type PostApiV1ExperimentsCorrosionListComplete = {
    data: PostApiV1ExperimentsCorrosionListCompleteMutationResponse;
    error: PostApiV1ExperimentsCorrosionListComplete400 | PostApiV1ExperimentsCorrosionListComplete401 | PostApiV1ExperimentsCorrosionListComplete403 | PostApiV1ExperimentsCorrosionListComplete404;
    request: PostApiV1ExperimentsCorrosionListCompleteMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: PostApiV1ExperimentsCorrosionListCompleteMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiV1ExperimentsCorrosionListCompleteClient>[0]>;
        return: Awaited<ReturnType<PostApiV1ExperimentsCorrosionListCompleteClient>>;
    };
};
/**
     * @link /api/v1/experiments/corrosion/list-complete */
export function usePostApiV1ExperimentsCorrosionListComplete(options: {
    mutation?: UseMutationOptions<PostApiV1ExperimentsCorrosionListComplete["response"], PostApiV1ExperimentsCorrosionListComplete["error"], PostApiV1ExperimentsCorrosionListComplete["request"]>;
    client?: PostApiV1ExperimentsCorrosionListComplete["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiV1ExperimentsCorrosionListComplete["data"], PostApiV1ExperimentsCorrosionListComplete["error"], PostApiV1ExperimentsCorrosionListComplete["request"]>({
                method: "post",
                url: `/api/v1/experiments/corrosion/list-complete`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}