import client from "../../lib/api/client";
import { useMutation } from "@tanstack/react-query";
import type { PostApiV1ExperimentsCorrosionCopyOneMutationRequest, PostApiV1ExperimentsCorrosionCopyOneMutationResponse, PostApiV1ExperimentsCorrosionCopyOne400, PostApiV1ExperimentsCorrosionCopyOne401, PostApiV1ExperimentsCorrosionCopyOne403 } from "../models/PostApiV1ExperimentsCorrosionCopyOne";
import type { UseMutationOptions } from "@tanstack/react-query";

type PostApiV1ExperimentsCorrosionCopyOneClient = typeof client<PostApiV1ExperimentsCorrosionCopyOneMutationResponse, PostApiV1ExperimentsCorrosionCopyOne400 | PostApiV1ExperimentsCorrosionCopyOne401 | PostApiV1ExperimentsCorrosionCopyOne403, PostApiV1ExperimentsCorrosionCopyOneMutationRequest>;
type PostApiV1ExperimentsCorrosionCopyOne = {
    data: PostApiV1ExperimentsCorrosionCopyOneMutationResponse;
    error: PostApiV1ExperimentsCorrosionCopyOne400 | PostApiV1ExperimentsCorrosionCopyOne401 | PostApiV1ExperimentsCorrosionCopyOne403;
    request: PostApiV1ExperimentsCorrosionCopyOneMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: PostApiV1ExperimentsCorrosionCopyOneMutationResponse;
    client: {
        parameters: Partial<Parameters<PostApiV1ExperimentsCorrosionCopyOneClient>[0]>;
        return: Awaited<ReturnType<PostApiV1ExperimentsCorrosionCopyOneClient>>;
    };
};
/**
     * @link /api/v1/experiments/corrosion/copy-one */
export function usePostApiV1ExperimentsCorrosionCopyOne(options: {
    mutation?: UseMutationOptions<PostApiV1ExperimentsCorrosionCopyOne["response"], PostApiV1ExperimentsCorrosionCopyOne["error"], PostApiV1ExperimentsCorrosionCopyOne["request"]>;
    client?: PostApiV1ExperimentsCorrosionCopyOne["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<PostApiV1ExperimentsCorrosionCopyOne["data"], PostApiV1ExperimentsCorrosionCopyOne["error"], PostApiV1ExperimentsCorrosionCopyOne["request"]>({
                method: "post",
                url: `/api/v1/experiments/corrosion/copy-one`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}