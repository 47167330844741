import { useWatch } from 'react-hook-form';
import useFormContextExtended from './useFormContextExtended';
import { Dropdown, TextBox, valueAsNumberOptional } from '@components/form';
import { Option } from '@components/form/Dropdown/types';
import { CollapseCustom } from './collapse-custom';
import ShapeSurfaceArea from './shape-surface-area';
import { ShapeType, schema } from '../schema';
import { Collapse } from 'react-daisyui';

type CouponInformationProps = {
  metallurgies: Option[];
  shapes: Option[];
  openDefault?: boolean;
};

const CouponInformation = ({ metallurgies, shapes, openDefault = false }: CouponInformationProps) => {
  const { extendRegister, errors } = useFormContextExtended(schema);
  const shapeValue = useWatch({ name: 'shape' });

  return (
    <CollapseCustom openDefault={openDefault}>
      <Collapse.Title className="text-xl font-medium">Coupon Information</Collapse.Title>
      <Collapse.Content>
        <div
          id="print-coupon-section"
          className="flex h-[29rem]"
        >
          <div className="w-2/5 px-1">
            <TextBox
              label="Coupon ID"
              error={errors.couponId?.message}
              {...extendRegister('couponId')}
            />
            <Dropdown
              label="Metallurgy"
              {...extendRegister('metallurgy')}
              error={errors.metallurgy?.message}
              options={metallurgies}
            />
            <TextBox
              type="number"
              label={
                <span>
                  Metallurgy Density, g/cm<sup>3</sup>
                </span>
              }
              error={errors.metallurgyDensity?.message}
              {...extendRegister('metallurgyDensity', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <Dropdown
              label="Shape"
              {...extendRegister('shape')}
              error={errors.shape?.message}
              options={shapes}
            />
            <TextBox
              type="number"
              label="Initial Weight, g"
              error={errors.initialWeight?.message}
              {...extendRegister('initialWeight', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </div>
          <ShapeSurfaceArea shape={shapeValue as ShapeType} />
        </div>
      </Collapse.Content>
    </CollapseCustom>
  );
};

export default CouponInformation;
