import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import { AcidBlendReportProps, CorrosionReport } from '../CorrosionReport';
import { usePrintToPdf } from '@hooks/usePrintToPdf';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useFormContext } from 'react-hook-form';
import { FormInput } from '../schema';
import { mapFormInputToReportProps } from '../mapFormInputToReportProps';

interface OrderMixingModalProps {}

const WorkSheetModalComponent: React.ForwardRefRenderFunction<HTMLDialogElement, OrderMixingModalProps> = (
  props,
  ref
) => {
  const { getValues } = useFormContext<FormInput>();
  const { printToPdf } = usePrintToPdf();
  const [isOpen, setIsOpen] = useState(false);
  const [reportProps, setReportProps] = useState<AcidBlendReportProps>();
  useEffect(() => {
    if (ref && typeof ref === 'object' && 'current' in ref) {
      const dialogElement = ref.current;

      const handleOpen = () => {
        setIsOpen(true);
        setReportProps(mapFormInputToReportProps(getValues()));
      };

      const handleClose = () => {
        setIsOpen(false);
      };

      if (dialogElement) {
        dialogElement.addEventListener('beforetoggle', handleOpen);
        dialogElement.addEventListener('close', handleClose);

        return () => {
          dialogElement.removeEventListener('beforetoggle', handleOpen);
          dialogElement.removeEventListener('close', handleClose);
        };
      }
    }
  }, [ref, getValues]);
  return (
    <Modal
      ref={ref}
      className="max-w-full"
    >
      <form method="dialog">
        <Button
          size="sm"
          color="ghost"
          shape="circle"
          className="absolute right-2 top-2"
        >
          <XMarkIcon className="w-5 h-5" />
        </Button>
      </form>
      <Modal.Header className="text-center">
        <Button
          color="secondary"
          size="sm"
          type="button"
          startIcon={<ArrowDownTrayIcon className="w-5 h-5 " />}
          onClick={async () => await printToPdf([{ id: 'print-acid-blend-report' }], 'worksheet')}
        >
          Download PDF
        </Button>
      </Modal.Header>
      <Modal.Body>{isOpen && reportProps && <CorrosionReport {...reportProps} />}</Modal.Body>
    </Modal>
  );
};

export const WorkSheetModal = React.memo(forwardRef(WorkSheetModalComponent));
