import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-daisyui';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { zodResolver } from '@hookform/resolvers/zod';
import { AdditionSchema, FormInput, schema } from './schema';
import ExperimentInformation from './components/experiment-information';
import CouponInformation from './components/coupon-information';
import AcidBlendRecipe from './components/acid-blend-recipe';
import Results from './components/results';
import Footer from './components/footer';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { shapes } from './dummyData';
import {
  useGetApiV1CatalogsDropdownsTypeid,
  useGetApiV1ExperimentsCorrosionExperimentid,
  usePostApiV1ExperimentsCorrosionCopyOne,
  usePutApiV1ExperimentsCorrosionExperimentid,
  usePutApiV1ExperimentsCorrosionExperimentidResultsImage,
} from '@sdk-client/hooks';
import { CreateCorrosionExperimentSch } from '@sdk-client/models';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { useLocation, useNavigate, useParams } from 'react-router';
import { convertStringToDate } from '@utils/dateUtils';
import { ImageType } from './components/types';
import { CatalogType } from '@pages/Admin/DropDownListData/types';
import { SelectAutocompleteOption } from '@components/form/SelectAutocomplete';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { OpenedExperiment } from '@pages/experiments/MultipleView';
import { WorkSheetModal } from './components/WorkSheetModal';

export type CorrosionUpdateProps = {
  experimentId?: string;
};

export const CorrosionUpdate = ({ experimentId = '' }: CorrosionUpdateProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSaved, setIsSaved] = useState(false);
  const { id = experimentId } = useParams();
  const printReportModalRef = useRef<HTMLDialogElement>(null);

  const { openedExperiments } = (location.state as { openedExperiments: OpenedExperiment[] }) || {
    openedExperiments: [],
  };

  const {
    mutate,
    isPending: isCopyPending,
    isError: IsCopyError,
    error: copyError,
  } = usePostApiV1ExperimentsCorrosionCopyOne({
    mutation: {
      onSuccess: (responseData, postedData) => {
        const newOpenedExperiments = [
          ...openedExperiments,
          { key: responseData.id, expNumber: responseData.experimentNumber, checked: true },
        ];
        navigate(`/experiments/multipleview/`, { state: { openedExperiments: newOpenedExperiments } });
        console.log('Copy experiment successful:', responseData);
      },
    },
  });

  const handleCopyOne = () => {
    mutate({ experimentId: id });
  };

  const { data: experiment, isPending: isExperimentPending } = useGetApiV1ExperimentsCorrosionExperimentid(id);
  const { mutateAsync: mutateFormAsync, isPending: isPendingForm } = usePutApiV1ExperimentsCorrosionExperimentid(id, {
    mutation: {
      onSuccess: () => {
        if (!isSaved) {
          navigate('/experiments/corrosion');
        }
      },
    },
  });

  const { mutateAsync: resultsImageMutateAsync } = usePutApiV1ExperimentsCorrosionExperimentidResultsImage(id, {
    client: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });

  const { data: equipments, isPending: isPendingEquipments } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.EQUIPMENTS)
  );
  const { data: cellNumbers, isPending: isPendingCellNumbers } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.CELL_NUMBERS)
  );
  const { data: metallurgies, isPending: isPendingMetallurgies } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.METALLURGIES)
  );
  const { data: pittingIndexSource, isPending: isPendingpittingIndexSource } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.PITTING_INDEX_SOURCE)
  );
  const { data: locationsSource, isPending: isPendingLocationsSource } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.LOCATION)
  );

  const dateInitialValue = {
    startDate: convertStringToDate(experiment?.date || ''),
    endDate: convertStringToDate(experiment?.date || ''),
  };

  const dateState = useState<DateValueType>(dateInitialValue);
  const projectIdState = useState<SelectAutocompleteOption | null>({
    value: experiment?.projectID || '',
    label: experiment?.projectID || '',
  });
  const projectNameState = useState<SelectAutocompleteOption | null>({
    value: experiment?.projectID || '',
    label: experiment?.projectID || '',
  });
  const [, setDateState] = dateState;
  const [, setProjectIdState] = projectIdState;
  const [, setProjectNameState] = projectNameState;

  const methods = useForm<FormInput>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    values: {
      experimentId: experiment?.id || '',
      date: experiment?.date || '',
      operator: experiment?.operator || '',
      projectID: experiment?.projectID || '',
      equipment: experiment?.equipment || '',
      cellNumber: experiment?.cellNumber || '',
      temperatureDegF: experiment?.temperatureDegF || 0,
      tempRampUpTime: experiment?.tempRampUpTime || 0,
      pressure: experiment?.pressure || 0,
      incubationTime: experiment?.incubationTime || 0,
      location: experiment?.location || '',
      customer: experiment?.customer || '',
      projectName: experiment?.projectName || '',
      testId: experiment?.testId || '',
      couponId: experiment?.couponId || '',
      h2sPerc: experiment?.h2sPerc || 0,
      purpose: experiment?.purpose || '',
      metallurgy: experiment?.metallurgy || '',
      metallurgyDensity: experiment?.metallurgyDensity || 0,
      initialWeight: experiment?.initialWeight || 0,
      shape: experiment?.shape || '',
      sampleVolumeMl: experiment?.sampleVolumeMl || 0,
      acidBlendTemperatureDegC: experiment?.acidBlendTemperatureDegC || 0,
      hollowCylinder: experiment?.hollowCylinder
        ? {
            diameter1: experiment.hollowCylinder.diameter1 ?? 0,
            diameter2: experiment.hollowCylinder.diameter2 ?? 0,
            length: experiment.hollowCylinder.length ?? 0,
            surfaceArea: experiment.hollowCylinder.surfaceArea ?? 0,
          }
        : undefined,
      cylinder: experiment?.cylinder
        ? {
            diameter: experiment.cylinder.diameter ?? 0,
            length: experiment.cylinder.length ?? 0,
            surfaceArea: experiment.cylinder.surfaceArea ?? 0,
          }
        : undefined,
      curvedSampleNoHole: experiment?.curvedSampleNoHole
        ? {
            length: experiment.curvedSampleNoHole.length ?? 0,
            width1: experiment.curvedSampleNoHole.width1 ?? 0,
            width2: experiment.curvedSampleNoHole.width2 ?? 0,
            thickness: experiment.curvedSampleNoHole.thickness ?? 0,
            surfaceArea: experiment.curvedSampleNoHole.surfaceArea ?? 0,
          }
        : undefined,
      curvedSampleWithHole: experiment?.curvedSampleWithHole
        ? {
            length: experiment.curvedSampleWithHole.length ?? 0,
            width1: experiment.curvedSampleWithHole.width1 ?? 0,
            width2: experiment.curvedSampleWithHole.width2 ?? 0,
            thickness: experiment.curvedSampleWithHole.thickness ?? 0,
            holeDiameter: experiment.curvedSampleWithHole.holeDiameter ?? 0,
            surfaceArea: experiment.curvedSampleWithHole.surfaceArea ?? 0,
          }
        : undefined,
      flatRectangularSquareNoHole: {
        length: experiment?.flatRectangularSquareNoHole?.length || 0,
        width: experiment?.flatRectangularSquareNoHole?.width || 0,
        thickness: experiment?.flatRectangularSquareNoHole?.thickness || 0,
        surfaceArea: experiment?.flatRectangularSquareNoHole?.surfaceArea || 0,
      },
      flatRectangularSquareWithHole: {
        length: experiment?.flatRectangularSquareWithHole?.length || 0,
        width: experiment?.flatRectangularSquareWithHole?.width || 0,
        thickness: experiment?.flatRectangularSquareWithHole?.thickness || 0,
        holeDiameter: experiment?.flatRectangularSquareWithHole?.holeDiameter || 0,
        surfaceArea: experiment?.flatRectangularSquareWithHole?.surfaceArea || 0,
      },
      customShape: {
        surfaceArea: experiment?.customShape?.surfaceArea || 0,
      },
      acidBlendAdditions: experiment?.acidBlendAdditions as AdditionSchema[],
      results: {
        finalWeight: experiment?.results?.finalWeight || 0,
        weightLoss: experiment?.results?.weightLoss || 0,
        corrosionLoss: experiment?.results?.corrosionLoss || 0,
        corrosionRateMpy: experiment?.results?.corrosionRateMpy || 0,
        corrosionRateMmpy: experiment?.results?.corrosionRateMmpy || 0,
        pittingIndex: experiment?.results?.pittingIndex || '',
        observations: experiment?.results?.observations || '',
      },
      surfaceArea:
        experiment?.hollowCylinder?.surfaceArea ||
        experiment?.cylinder?.surfaceArea ||
        experiment?.curvedSampleNoHole?.surfaceArea ||
        experiment?.curvedSampleWithHole?.surfaceArea ||
        experiment?.flatRectangularSquareNoHole?.surfaceArea ||
        experiment?.flatRectangularSquareWithHole?.surfaceArea ||
        experiment?.customShape?.surfaceArea ||
        0,
    },
  });

  const date = useMemo(() => convertStringToDate(experiment?.date || ''), [experiment?.date]);
  const projectID = useMemo(() => experiment?.projectID || '', [experiment?.projectID]);
  const projectName = useMemo(() => experiment?.projectName || '', [experiment?.projectName]);

  useEffect(() => {
    setDateState({
      startDate: date,
      endDate: date,
    });
  }, [date, setDateState]);

  useEffect(() => {
    setProjectIdState({
      value: projectID,
      label: projectID,
    });
    setProjectNameState({
      value: projectName,
      label: projectName,
    });
  }, [projectID, setProjectIdState, projectName, setProjectNameState]);

  if (
    isExperimentPending ||
    isPendingForm ||
    isPendingEquipments ||
    isPendingCellNumbers ||
    isPendingMetallurgies ||
    isPendingpittingIndexSource ||
    isPendingLocationsSource
  )
    return <LoadingRing />;

  const handleImageUpload = async (imageData: ImageType, userId: string): Promise<void> => {
    if (imageData.image && (imageData.image as Blob)?.type) {
      return resultsImageMutateAsync({
        type: imageData.type,
        userId,
        image: imageData.image as string,
      }).catch((error) => {
        console.error(`Error uploading image of type ${imageData.type}:`, error);
      });
    }
  };

  const uploadImagesAndForm = async (
    data: FormInput,
    userId: string,
    body: CreateCorrosionExperimentSch
  ): Promise<void> => {
    const imageTypes: ImageType[] = [
      { image: data?.results?.frontPreTestImg, type: 'FrontPreTest' },
      { image: data?.results?.frontPostTestImg, type: 'FrontPostTest' },
      { image: data?.results?.backPreTestImg, type: 'BackPreTest' },
      { image: data?.results?.backPostTestImg, type: 'BackPostTest' },
    ];

    for (const imageData of imageTypes) {
      await handleImageUpload(imageData, userId);
    }

    await mutateFormAsync(body).catch((error) => {
      console.error('Error submitting form:', error);
    });
  };

  const onSubmit: SubmitHandler<FormInput> = async (data, e) => {
    const nativeEvent = e?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter;
    setIsSaved(submitter?.id === 'save');
    const body: CreateCorrosionExperimentSch = {
      date: data.date || undefined,
      operator: data.operator,
      projectID: data.projectID,
      equipment: data.equipment,
      location: data.location,
      customer: data.customer,
      projectName: data.projectName,
      testId: data.testId,
      couponId: data.couponId,
      h2sPerc: data.h2sPerc,
      purpose: data.purpose,
      cellNumber: data.cellNumber,
      temperatureDegF: data.temperatureDegF,
      tempRampUpTime: data.tempRampUpTime,
      pressure: data.pressure,
      incubationTime: data.incubationTime,
      metallurgy: data.metallurgy,
      metallurgyDensity: data.metallurgyDensity,
      initialWeight: data.initialWeight,
      shape: data.shape,
      sampleVolumeMl: data.sampleVolumeMl,
      acidBlendTemperatureDegC: data.acidBlendTemperatureDegC,
      acidBlendAdditions: data.acidBlendAdditions,
      hollowCylinder: data.hollowCylinder,
      cylinder: data.cylinder,
      curvedSampleNoHole: data.curvedSampleNoHole,
      curvedSampleWithHole: data.curvedSampleWithHole,
      flatRectangularSquareNoHole: data.flatRectangularSquareNoHole,
      flatRectangularSquareWithHole: data.flatRectangularSquareWithHole,
      customShape: data.customShape,
      results: data.results && {
        initialWeight: data.initialWeight,
        finalWeight: data.results.finalWeight,
        weightLoss: data.results.weightLoss ?? 0,
        corrosionLoss: data.results.corrosionLoss ?? 0,
        corrosionRateMpy: data.results.corrosionRateMpy ?? 0,
        corrosionRateMmpy: data.results.corrosionRateMmpy ?? 0,
        pittingIndex: data.results?.pittingIndex ?? '',
        observations: data.results?.observations ?? '',
      },
    };

    uploadImagesAndForm(data, '1', body); //TODO: userId is hardcoded for now
  };
  const cancelUpdate = () => {
    methods.reset();
    navigate('/experiments/corrosion');
    //setDateValue(dateInitialValue);
  };

  const formSave = () => {
    console.log('Form values: ', methods.getValues());
    console.log('Form Errors: ', methods.formState.errors);
  };
  const handlePrint = () => {
    printReportModalRef.current?.showModal();
  };

  return (
    <div className="flex flex-wrap gap-2">
      <FormProvider {...methods}>
        <Form
          className="shadow shadow-gray-600 bg-base-200 w-full rounded-lg p-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Footer
            experimentId={id}
            reset={cancelUpdate}
            save={formSave}
            onCopy={handleCopyOne}
            onPrint={handlePrint}
          />
          <ExperimentInformation
            openDefault={true}
            dateState={dateState}
            projectIdState={projectIdState}
            projectNameState={projectNameState}
            equipments={equipments ?? []}
            cellNumbers={cellNumbers ?? []}
            locations={locationsSource ?? []}
          />
          <CouponInformation
            openDefault={true}
            metallurgies={metallurgies ?? []}
            shapes={shapes}
          />
          <AcidBlendRecipe openDefault={true} />
          <Results
            experimentId={id}
            openDefault={true}
            pittingIndexSource={pittingIndexSource ?? []}
          />
          <Footer
            experimentId={id}
            reset={cancelUpdate}
            save={formSave}
            onCopy={handleCopyOne}
            onPrint={handlePrint}
          />
          <Button
            title="back"
            size="sm"
            color="primary"
            type="submit"
            className="fixed top-36 right-5 z-50"
            startIcon={<ArrowUturnLeftIcon className="h-6 w-6" />}
          ></Button>
          <WorkSheetModal ref={printReportModalRef} />
        </Form>
      </FormProvider>
    </div>
  );
};
