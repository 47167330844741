import { DatePicker, Dropdown, FormCheckbox, TextBox } from '@components/form';
import { Tabs } from 'react-daisyui';
import { Controller } from 'react-hook-form';
import TabContent from './tab-content';
import { TabProps } from './types';
import { Dispatch, SetStateAction, useRef } from 'react';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { CONTENT_TAB_STYLE } from './constants';
import useFormContextExtended from './useFormContextExtended';
import { dpConfig } from './dummy-dropdown-config';
import { CatalogType } from '@pages/Admin/DropDownListData/types';
import { useGetApiV1CatalogsDropdownsTypeid } from '@sdk-client/hooks';
import { LoadingRing } from '@components/loaders/LoadingRing';
import { FormPairRadios } from '@components/form/FormCheckbox/FormPairRadios';
import { SampleForH2SModal } from './sample-for-H2S-modal';

type RequestDetailsTabProps = {
  dateOfRequestState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
  dateFinalReportState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
} & TabProps;

const RequestDetailsTab = ({
  indexTab,
  activeTab,
  setActiveTab,
  dateOfRequestState,
  dateFinalReportState,
}: RequestDetailsTabProps) => {
  const [dateOfRequestValue, setDateOfRequestValue] = dateOfRequestState;
  const [dateFinalReportValue, setDateFinalReportValue] = dateFinalReportState;
  const { control, extendRegister, errors } = useFormContextExtended();
  const modalRef = useRef<HTMLDialogElement>(null);

  const { data: typeOfRequestData, isPending: isPendingTypeOfRequestData } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.TYPE_OF_REQUEST)
  );
  const { data: applicationData, isPending: isPendingApplicationData } = useGetApiV1CatalogsDropdownsTypeid(
    Number(CatalogType.APPLICATION)
  );
  const { data: pslData, isPending: isPendingpslData } = useGetApiV1CatalogsDropdownsTypeid(Number(CatalogType.PSL));

  if (isPendingTypeOfRequestData || isPendingApplicationData || isPendingpslData) return <LoadingRing />;

  console.log('RequestDetailsTab errors: ', errors);

  return (
    <Tabs.RadioTab
      checked={activeTab === indexTab}
      onChange={() => setActiveTab(indexTab)}
      name="section"
      label="Request Details"
      contentClassName={CONTENT_TAB_STYLE}
    >
      <TabContent>
        <div className="grow">
          <div className="w-fit">
            <TextBox
              label="Name"
              error={errors.name?.message}
              {...extendRegister('name')}
            />
            <TextBox
              label="Work Location (City, State, Country)"
              error={errors.workLocation?.message}
              {...extendRegister('workLocation')}
            />
            <TextBox
              label="Phone"
              error={errors.phone?.message}
              {...extendRegister('phone')}
            />
            <TextBox
              label="Email"
              error={errors.email?.message}
              {...extendRegister('email')}
            />
            <TextBox
              label="Customer"
              error={errors.customer?.message}
              {...extendRegister('customer')}
            />
          </div>
        </div>
        <div className="grow">
          <div className="w-fit">
            <Dropdown
              label="Request for a Tender/Bid?"
              {...extendRegister('isRequestForTenderBid')}
              error={errors.isRequestForTenderBid?.message}
              options={dpConfig.booleanOptions}
            />
            <TextBox
              label="Formation Name"
              {...extendRegister('formationName')}
            />
            <TextBox
              label="Field Name"
              {...extendRegister('fieldName')}
            />
            <Dropdown
              label="Type of Request"
              {...extendRegister('typeOfRequest')}
              error={errors.typeOfRequest?.message}
              options={typeOfRequestData}
            />
            <Dropdown
              label="Application"
              {...extendRegister('application')}
              error={errors.status?.message}
              options={applicationData}
            />
          </div>
        </div>
        <div className="grow">
          <div className="w-fit">
            <Dropdown
              label="PSL"
              error={errors.psl?.message}
              {...extendRegister('psl')}
              options={pslData}
            />
            <TextBox
              label="Project Revenue"
              error={errors.projectRevenue?.message}
              {...extendRegister('projectRevenue')}
            />
            <Controller
              name="dateOfRequest"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date of Request"
                  value={dateOfRequestValue}
                  onChange={(newValue) => {
                    field.onChange(newValue?.startDate);
                    field.value = newValue?.startDate as string;
                    setDateOfRequestValue(newValue);
                  }}
                  error={error?.message}
                />
              )}
            />
            <Controller
              name="dateFinalReportRequested"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date Final Report is Requested"
                  is_required="true"
                  popoverDirection="down"
                  zIndex={20}
                  value={dateFinalReportValue}
                  onChange={(newValue) => {
                    field.onChange(newValue?.startDate);
                    field.value = newValue?.startDate as string;
                    setDateFinalReportValue(newValue);
                  }}
                  error={error?.message}
                />
              )}
            />
            <Dropdown
              label="Shipping samples?"
              {...extendRegister('isShippingSamples')}
              error={errors.isShippingSamples?.message}
              options={dpConfig.booleanOptions}
            />
            <SampleForH2SModal ref={modalRef} />
          </div>
        </div>
        <div className="grow">
          <div className="w-fit">
            <Controller
              name="isSampleCheckedForH2s"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormPairRadios
                  label={
                    <>
                      Sample Checked for H<sub>2</sub>S
                    </>
                  }
                  is_required="true"
                  checked1={field.value === true}
                  checked2={field.value === false}
                  onChange1={() => {
                    field.onChange(true);
                  }}
                  onChange2={() => {
                    field.onChange(false);
                    modalRef.current?.showModal();
                  }}
                  error={error?.message}
                />
              )}
            />
            <TextBox
              type="number"
              label={
                <>
                  H<sub>2</sub>S Content (ppm)
                </>
              }
              error={errors.h2sContent?.message}
              {...extendRegister('h2sContent')}
            />
            <TextBox
              label="Cost Center"
              error={errors.costCenter?.message}
              {...extendRegister('costCenter')}
            />
          </div>
        </div>
      </TabContent>
    </Tabs.RadioTab>
  );
};

export default RequestDetailsTab;
