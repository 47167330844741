import { Hero } from 'react-daisyui';
import { InitiateRequestButton } from './initiate-request-button';
import { RequestLocation } from './types';

export interface RequestLocationCardProps {
  requestCardPrompt: string;
  imageSrc: string;
  location: RequestLocation;
  onSelectionMade: (location: RequestLocation) => void;
}

export const RequestLocationCard = ({
  location,
  onSelectionMade,
  imageSrc,
  requestCardPrompt,
}: RequestLocationCardProps) => {
  return (
    <Hero>
      <Hero.Content>
        <div className="flex flex-col items-center">
          <img
            src={imageSrc}
            className="max-w-full rounded-xl cursor-pointer"
            onClick={() => onSelectionMade('Houston')}
          />
          <div className="flex flex-col items-center">
            <h1 className="text-lg font-semibold">{location} Tech Services</h1>
            <p className="w-[370px] py-6">{requestCardPrompt}</p>
            <InitiateRequestButton
              className="btn btn-primary w-auto m-auto"
              location="Houston"
              onClick={onSelectionMade}
            />
          </div>
        </div>
      </Hero.Content>
    </Hero>
  );
};
