import { DatePicker, Dropdown, TextBox, valueAsNumberOptional } from '@components/form';
import useFormContextExtended from './useFormContextExtended';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Option, OptionRelated } from '@components/form/Dropdown/types';
import { Collapse } from 'react-daisyui';
import { Controller, useWatch } from 'react-hook-form';
import { DateValueType } from 'react-tailwindcss-datepicker';
import { schema } from '../schema';
import { CollapseCustom } from './collapse-custom';
import { SelectAutocomplete, SelectAutocompleteOption } from '@components/form/SelectAutocomplete';

type ExperimentInformationProps = {
  dateState: [DateValueType, Dispatch<SetStateAction<DateValueType>>];
  projectIdState: [SelectAutocompleteOption | null, Dispatch<SetStateAction<SelectAutocompleteOption | null>>];
  projectNameState: [SelectAutocompleteOption | null, Dispatch<SetStateAction<SelectAutocompleteOption | null>>];
  equipments: Option[];
  cellNumbers: Option[];
  locations: Option[];
  openDefault?: boolean;
};

function ExperimentInformation({
  dateState,
  projectIdState,
  projectNameState,
  equipments,
  cellNumbers,
  locations,
  openDefault = false,
}: ExperimentInformationProps) {
  const [dateValue, setDateValue] = dateState;
  const { control, extendRegister, errors } = useFormContextExtended(schema);
  const [filteredCellNumbers, setFilteredCellNumbers] = useState<Option[]>(cellNumbers);
  const [projectSelectedOption, setProjectSelectedOption] = projectIdState;
  const [projectNameSelectedOption, setProjectNameSelectedOption] = projectNameState;

  const equipmentValue = useWatch({
    control,
    name: 'equipment',
  });

  useEffect(() => {
    const currentEquipmentValue = equipmentValue;

    if (currentEquipmentValue) {
      const selectedOption = equipments.find((x) => x.value === currentEquipmentValue) as OptionRelated;

      if (selectedOption) {
        // Filter cellNumbers based on the selected equipment
        const filtered = cellNumbers.filter((cellNumber) => selectedOption.relatedKeys.includes(cellNumber.value));
        setFilteredCellNumbers(filtered);
      }
    } else {
      // reset when no equipment is selected
      setFilteredCellNumbers([]);
    }
  }, [equipmentValue, equipments, cellNumbers]);

  return (
    <CollapseCustom openDefault={openDefault}>
      <Collapse.Title className="text-xl font-medium ">Experiment Information</Collapse.Title>
      <Collapse.Content>
        <div className="flex">
          <div className="w-2/5 px-1">
            <Controller
              name="date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date"
                  is_required="true"
                  value={dateValue}
                  onChange={(newValue) => {
                    field.onChange(newValue?.startDate);
                    field.value = newValue?.startDate as string;
                    setDateValue(newValue);
                  }}
                  error={error?.message}
                />
              )}
            />
            <Dropdown
              label="Location"
              {...extendRegister('location')}
              error={errors.location?.message}
              options={locations}
            />
            <TextBox
              label="Technician"
              error={errors.operator?.message}
              {...extendRegister('operator')}
            />
            <div className="z-20">
              <Controller
                name="projectName"
                control={control}
                defaultValue={''}
                render={({ field, fieldState: { error } }) => (
                  <SelectAutocomplete
                    {...field}
                    is_required="true"
                    label="Project Name"
                    selectedOption={projectNameSelectedOption}
                    error={error?.message}
                    onChange={(selected) => {
                      if (selected) {
                        setProjectNameSelectedOption({ label: selected.label, value: selected.label });
                        setProjectSelectedOption({ label: selected.value, value: selected.value });
                        field.onChange(selected?.label);
                      }
                    }}
                  />
                )}
              />
            </div>
            <Controller
              name="projectID"
              control={control}
              defaultValue={''}
              render={({ field, fieldState: { error } }) => (
                <SelectAutocomplete
                  {...field}
                  asyncPaginateClassName="z-9"
                  is_required="true"
                  label="Project ID"
                  selectedOption={projectSelectedOption}
                  error={error?.message}
                  onChange={(selected) => {
                    if (selected) {
                      setProjectNameSelectedOption({ label: selected.label, value: selected.label });
                      setProjectSelectedOption({ label: selected.value, value: selected.value });
                      field.onChange(selected?.label);
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="w-2/5 px-1">
            <TextBox
              label="Customer"
              error={errors.customer?.message}
              {...extendRegister('customer')}
            />
            <Dropdown
              label="Equipment"
              {...extendRegister('equipment')}
              error={errors.equipment?.message}
              options={equipments}
            />
            <Dropdown
              label="Cell Number"
              {...extendRegister('cellNumber')}
              error={errors.cellNumber?.message}
              options={filteredCellNumbers}
            />
            <TextBox
              type="number"
              label="Temperature (deg F)"
              error={errors.temperatureDegF?.message}
              {...extendRegister('temperatureDegF', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              label="Temp Ramp Up Time (min)"
              error={errors.tempRampUpTime?.message}
              {...extendRegister('tempRampUpTime', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </div>
          <div className="w-2/5 px-1">
            <TextBox
              type="number"
              label="Initial Pressure (psig)"
              error={errors.pressure?.message}
              {...extendRegister('pressure', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              label="Test Duration (h)"
              error={errors.incubationTime?.message}
              {...extendRegister('incubationTime', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              label="Purpose"
              error={errors.purpose?.message}
              {...extendRegister('purpose')}
            />
            <TextBox
              label="Test ID"
              error={errors.testId?.message}
              {...extendRegister('testId')}
            />
            <TextBox
              type="number"
              label={
                <>
                  H<sub>2</sub>S (%)
                </>
              }
              error={errors.h2sPerc?.message}
              {...extendRegister('h2sPerc', {
                setValueAs: valueAsNumberOptional,
              })}
            />
          </div>
        </div>
      </Collapse.Content>
    </CollapseCustom>
  );
}

export default ExperimentInformation;
