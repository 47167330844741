import { LogoName } from '@components/svg/logos';
import React from 'react';
import { couponImages } from './components/shape-surface-area';
import { ShapeType } from './schema';

export type DimensionsType = {
  w1?: string;
  w2?: string;
  length?: string;
  thickness?: string;
  holeDiameter?: string;
  surfaceArea?: string;
};

export interface AcidBlendReportProps {
  projectId?: string;
  projectName?: string;
  technician?: string;
  experimentInfo?: {
    date?: string;
    testId?: string;
    acidBlend?: string;
    blendVolume?: string;
    temperature?: string;
    incubationTime?: string;
    equipment?: string;
    cellNumber?: string;
    timeTestStarted?: string;
  };
  acidBlendRecipe?: {
    order?: number;
    physicalState?: string;
    additiveType?: string;
    additive?: string;
    concentration?: string;
    volumeMass?: string;
  }[];
  couponInfo?: {
    metallurgy?: string;
    couponId?: string;
    couponShape?: string;
    initialWeight?: string;
    finalWeight?: string;
    weightLoss?: string;
    corrosionLoss?: string;
    dimensions?: DimensionsType;
  };
  notes?: string;
}

export const CorrosionReport: React.FC<AcidBlendReportProps> = ({
  projectId = '',
  projectName = '',
  technician = '',
  experimentInfo = {},
  acidBlendRecipe = [],
  couponInfo = {},
  notes = '',
}) => {
  const formatVolumeMassUnit = (value?: string) => {
    return value ? (value.toLowerCase() === 'solid' ? 'g' : 'mL') : '';
  };
  const formatConcentrationUnit = (additiveTypeValue?: string, physicalStateValue?: string) => {
    if (additiveTypeValue === 'acid' || additiveTypeValue === 'salt') {
      return '%';
    }

    if (physicalStateValue) {
      return physicalStateValue.toLowerCase() === 'solid' ? 'ppt' : 'gpt';
    }

    return '';
  };
  return (
    <>
      <div
        id="print-acid-blend-report"
        className="p-4 border border-black text-sm"
      >
        {/* Header */}
        <div className="grid grid-cols-10 border border-black mb-2">
          <div className="col-span-6 border-r border-black text-center">
            <h1 className="text-xl font-bold">Halliburton Technology Center - Corrosion Laboratory</h1>
            <h2 className="text-lg">Stimulation & Intervention Chemistry Technical Services</h2>
          </div>
          <div className="col-span-4 content-evenly justify-self-center">
            <LogoName className="h-9" />
          </div>
        </div>

        {/* Project Information */}
        <div className="grid grid-cols-3 border border-black">
          <div className="bg-red-600 text-white p-2 border-r text-center border-black font-bold">Project ID</div>
          <div className="bg-red-600 text-white p-2 border-r text-center border-black font-bold">Project Name</div>
          <div className="bg-red-600 text-white p-2 text-center font-bold">Technician</div>
          <div className="p-2 border-r border-black">{projectId}</div>
          <div className="p-2 border-r border-black">{projectName}</div>
          <div className="p-2">{technician}</div>
        </div>

        {/* Experiment Information and Acid Blend Recipe Section */}
        <div className="grid grid-cols-10 border border-black mt-2">
          {/* Experiment Information Subsection (Static, 40% width) */}
          <div className="col-span-3 border-r border-black">
            <div className="bg-red-600 text-white p-2 text-center font-bold border-b border-black">
              Experiment Information
            </div>
            <div className="grid grid-cols-[2fr_3fr]">
              {[
                { label: 'Date', value: experimentInfo.date },
                { label: 'Test ID', value: experimentInfo.testId },
                { label: 'Acid Blend', value: experimentInfo.acidBlend },
                { label: 'Blend Volume (mL)', value: experimentInfo.blendVolume },
                { label: 'Temperature (°F)', value: experimentInfo.temperature },
                { label: 'Incubation Time (h)', value: experimentInfo.incubationTime },
                { label: 'Equipment', value: experimentInfo.equipment },
                { label: 'Cell Number', value: experimentInfo.cellNumber },
                { label: 'Time Test Started', value: experimentInfo.timeTestStarted },
              ].map(({ label, value }, index) => (
                <React.Fragment key={index}>
                  <div className="border border-black p-1 bg-gray-200 text-xs font-semibold">{label}</div>
                  <div className="border border-black p-1 text-xs">{value || ''}</div>
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Acid Blend Recipe Subsection (Dynamic, 60% width) */}
          <div className="col-span-7">
            <div className="bg-red-600 text-white p-2 text-center font-bold border-b border-black">
              Acid Blend Recipe
            </div>
            <div className="grid grid-cols-[auto_1fr_1fr_1fr_1fr] text-xs">
              {/* Headers */}
              {['Order', 'Physical State', 'Additive', 'Concentration', 'Volume/Mass'].map((header, index) => (
                <div
                  key={index}
                  className="border border-black p-1 bg-gray-600 text-white text-center text-xs font-semibold"
                >
                  {header}
                </div>
              ))}

              {/* Dynamic Rows */}
              {acidBlendRecipe.map((recipe, recipeIndex) => (
                <React.Fragment key={recipeIndex}>
                  <div className="border border-black p-1 text-center">{recipe.order || ''}</div>
                  <div className="border border-black p-1">{recipe.physicalState || ''}</div>
                  <div className="border border-black p-1">{recipe.additive || ''}</div>
                  <div className="border border-black p-1 flex justify-between">
                    <div>{recipe.concentration || ''}</div>
                    <div>{formatConcentrationUnit(recipe.additiveType, recipe.physicalState)}</div>
                  </div>
                  <div className="border border-black p-1 flex justify-between">
                    <div>{recipe.volumeMass || ''}</div>
                    <div>{formatVolumeMassUnit(recipe.physicalState)}</div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-10 border border-black mt-2">
          {/* Experiment Information Subsection (Static, 40% width) */}
          <div className="col-span-3 border-r border-black">
            <div className="bg-red-600 text-white p-2 text-center font-bold border-b border-black">
              Experiment Information
            </div>
            <div className="grid grid-cols-[2fr_3fr]">
              {[
                { label: 'Metallurgy', value: couponInfo.metallurgy },
                { label: 'Coupon ID', value: couponInfo.couponId },
                { label: 'Coupon Shape', value: couponInfo.couponShape },
                { label: 'Initial Weight (g)', value: couponInfo.initialWeight },
                { label: 'Final Weight (g)', value: couponInfo.finalWeight },
                { label: 'Weight Loss (g)', value: couponInfo.weightLoss },
                {
                  label: (
                    <>
                      Corrosion Loss (lb/ft<sup>2</sup>)
                    </>
                  ),
                  value: couponInfo.corrosionLoss,
                },
              ].map(({ label, value }, index) => (
                <React.Fragment key={index}>
                  <div className="border border-black p-1 text-xs font-semibold">{label}</div>
                  <div className="border border-black p-1">{value || ''}</div>
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Coupon Dimensions Subsection (Dynamic, 60% width) */}
          <div className="col-span-7">
            <div className="bg-red-600 text-white p-2 text-center font-bold border-b border-black">
              Coupon Dimensions
            </div>
            <div className="grid grid-cols-[1fr_1fr_6fr] gap-2 w-full">
              {/* Dimensions Grid */}
              <div className="col-span-2 grid grid-cols-[1fr_2fr]">
                {[
                  { label: 'W1 (in)', value: couponInfo.dimensions?.w1 },
                  { label: 'W2 (in)', value: couponInfo.dimensions?.w2 },
                  { label: 'L (in)', value: couponInfo.dimensions?.length },
                  { label: 'T (in)', value: couponInfo.dimensions?.thickness },
                  { label: 'D (in)', value: couponInfo.dimensions?.holeDiameter },
                  {
                    label: (
                      <>
                        SA (in<sup>2</sup>)
                      </>
                    ),
                    value: couponInfo.dimensions?.surfaceArea,
                  },
                ].map(({ label, value }, index) => (
                  <React.Fragment key={index}>
                    <div className="border border-black p-1 text-xs">{label}</div>
                    <div className="border border-black p-1">{value || ''}</div>
                  </React.Fragment>
                ))}
              </div>

              {/* Image Container */}
              <div className="col-span-1 flex items-center justify-center">
                {couponInfo.couponShape && (
                  <img
                    src={couponImages[couponInfo.couponShape as ShapeType]}
                    alt={`Shape ${couponInfo.couponShape}`}
                    className="w-52 h-52 object-contain"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-10 border border-black mb-2 bg-gray-600 text-white">
          <div className="col-span-5 border-r border-black text-center">
            <p>0.3175/Surface Area of Coupon = Factor</p>
          </div>
          <div className="col-span-5 content-evenly justify-self-center">
            <p>Initial Weight - Final Weight x Factor = Corrosion Loss (lb/ft2)</p>
          </div>
        </div>
        {/* Notes & Observations */}
        <div className="border border-black mt-2">
          <div className="bg-red-600 text-white p-2 border-b border-black font-bold text-center">
            Notes & Observations
          </div>
          <div className="p-2 min-h-[100px]">{notes}</div>
        </div>
      </div>
    </>
  );
};
