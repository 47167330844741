import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { forwardRef } from 'react';
import { Button, Modal } from 'react-daisyui';

interface OrderMixingModalProps {}

const SampleForH2SModalComponent: React.ForwardRefRenderFunction<HTMLDialogElement, OrderMixingModalProps> = (
  props,
  ref
) => {
  return (
    <Modal
      ref={ref}
      className="w-full max-w-3xl"
    >
      <form method="dialog">
        <Button
          size="sm"
          color="ghost"
          shape="circle"
          className="absolute right-2 top-2"
        >
          <XMarkIcon className="w-5 h-5" />
        </Button>
      </form>
      <Modal.Header className="font-bold"></Modal.Header>
      <Modal.Body>
        <p>
          All samples must be checked for H<sub>2</sub>S prior to shipping to any Halliburton Technology Center.
        </p>
        <br />
        <p>
          Contact the Technical Services team prior to shipping any samples that are known to contain H<sub>2</sub>S.
        </p>
        <br />
        <p>
          If a sample containing H<sub>2</sub>S is received at a facility that cannot accept it (e.g. North Belt), the
          sample will be immediately discarded, and the disposal cost will be charged to the shipper.
          (GD-GL-HAL-MLC-TECH-0502)
        </p>
      </Modal.Body>
    </Modal>
  );
};

export const SampleForH2SModal = React.memo(forwardRef(SampleForH2SModalComponent));
