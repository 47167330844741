import * as XLSX from 'xlsx';

interface UseExportExcelParams {
  data: any[];
  filename?: string;
  sheetName?: string;
}

export const useExportExcel = () => {
  const flattenObject = (obj: any, prefix = ''): Record<string, any> => {
    const result: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key === '_id' && value && typeof value === 'object' && '$oid' in value) {
        result[prefix + key] = value.$oid;
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item && typeof item === 'object') {
            const nested = flattenObject(item, `${prefix}${key}[${index}].`);
            Object.assign(result, nested);
          }
        });
      } else if (value && typeof value === 'object' && !Array.isArray(value)) {
        const nested = flattenObject(value, `${prefix}${key}.`);
        Object.assign(result, nested);
      } else {
        result[prefix + key] = value ?? '';
      }
    }
    return result;
  };

  const handleExport = ({ data, filename = 'data_export', sheetName = 'Data' }: UseExportExcelParams) => {
    try {
      // Get all unique keys from flattened objects
      const allFlattened = data.map((item) => flattenObject(item));
      const allKeys = Array.from(new Set(allFlattened.flatMap((obj) => Object.keys(obj)))); //.sort(); // Sort for consistent column order

      // Prepare headers
      const headers = allKeys;

      // Prepare data rows
      const rows = allFlattened.map((obj) => {
        return headers.map((key) => obj[key] ?? '');
      });

      // Create worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

      // Set column widths
      const columnWidths = headers.map(() => ({ wch: 10 }));
      worksheet['!cols'] = columnWidths;

      //Apply basic formatting
      const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          const cell = worksheet[cellRef];
          if (cell) {
            if (!cell.s) cell.s = {};
            cell.s = {
              ...cell.s,
              alignment: {
                vertical: 'top',
                horizontal: 'left',
                wrapText: true,
              },
            };
          }
        }
      }

      // Create workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // Generate Excel file
      XLSX.writeFile(workbook, `${filename}_${formatDate(new Date())}.xlsx`, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary',
        cellStyles: true,
      });
    } catch (error) {
      console.error('Error exporting complex data to Excel:', error);
    }
  };

  return {
    handleExport,
  };
};

// Helper function to format date for filename
const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};
