import { forwardRef, useEffect, useRef, useState } from 'react';
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Group, Option } from './types';
import { debounce } from 'lodash';
interface ComboboxProps {
  groups: Group[];
  placeholder?: string;
  onChange?: (selectedOption: Option | null) => void;
  initialSelectedOption: Option | null;
  rowId?: string;
}

export const GroupedCombobox = forwardRef<HTMLInputElement, ComboboxProps>(
  ({ groups, placeholder, onChange, initialSelectedOption, rowId = '', ...rest }, ref) => {
    const [selected, setSelected] = useState<Option | null>(null);
    const [query, setQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const optionsRef = useRef(null);

    const debouncedSetQuery = debounce((value) => {
      setQuery(value);
      // open the menu when the user starts typing
      if (value.trim() !== '') {
        setIsOpen(true);
      }
    }, 200);

    // use effect to clean up state when the component unmounts
    useEffect(() => {
      return () => {
        setQuery('');
        setIsOpen(false);
      };
    }, []);

    useEffect(() => {
      if (initialSelectedOption) {
        setSelected(initialSelectedOption);
      }
    }, [initialSelectedOption]);

    // effect to handle when the query changes
    useEffect(() => {
      // if there is a query and there are filtered results, open the menu
      if (query.trim() !== '' && filteredGroups.length > 0) {
        setIsOpen(true);
      }
    }, [query]);

    const filteredGroups = groups
      .map((group) => ({
        ...group,
        options: group.options.filter((option) => option.text.toLowerCase().includes(query.toLowerCase())),
      }))
      .filter((group) => group.options.length > 0);

    const handleChange = (option: Option | null) => {
      setSelected(option);
      setIsOpen(false);
      if (onChange) {
        onChange(option);
      }
    };

    // Handle the opening/closing of the dropdown
    const handleToggle = () => {
      setIsOpen(!isOpen);
      if (!isOpen) {
        // reset the query when opening
        setQuery('');
      }
    };

    // handle the text change in the input
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      // use RAF to sync with the render cycle
      requestAnimationFrame(() => {
        debouncedSetQuery(value);
      });
    };

    return (
      <Combobox
        key={`combobox-${rowId}`}
        value={selected}
        onChange={handleChange}
        onClose={() => {
          // no reset the query when closing to keep the filter
          setIsOpen(false);
        }}
      >
        <div className="relative">
          <ComboboxInput
            key={`input-${rowId}`}
            className="w-full rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-300 sm:text-sm"
            onChange={handleInputChange}
            onFocus={() => {
              // If there is a query when focusing, open the menu
              if (query.trim() !== '') {
                setIsOpen(true);
              }
            }}
            displayValue={(option: Option) => option?.text || ''}
            placeholder={placeholder}
            onClick={() => setIsOpen(true)}
          />
          <ComboboxButton
            key={`button-${rowId}`}
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            onClick={handleToggle}
          >
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>
        </div>
        {isOpen && filteredGroups.length > 0 && (
          <ComboboxOptions
            ref={optionsRef}
            key={`options-${rowId}`}
            className="absolute z-10 mt-1 max-h-60 w-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            anchor="bottom"
            static
          >
            {filteredGroups.map((group) => (
              <div key={`group-${rowId}-${group.text}`}>
                <div className="bg-gray-100 px-4 py-2 text-sm font-semibold text-gray-900">{group.text}</div>
                {group.options.map((option) => (
                  <ComboboxOption
                    key={`option-${rowId}-${option.value}`}
                    value={option}
                    className={({ focus }) =>
                      `relative cursor-default select-none py-2 pl-4 pr-4 ${
                        focus ? 'bg-blue-700 text-white' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ selected }) => (
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.text}
                      </span>
                    )}
                  </ComboboxOption>
                ))}
              </div>
            ))}
          </ComboboxOptions>
        )}
      </Combobox>
    );
  }
);

GroupedCombobox.displayName = 'GroupedCombobox';
