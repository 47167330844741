import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AcidBlendCellMeta, CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { Row } from '@components/tables/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { sortDropdownOptions } from '@utils/sortDropdownOptions';
import { GroupedCombobox, GroupedComboboxTypes } from '@components/form';

export const AdditiveGroupedCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  const meta = column.columnDef.meta as AcidBlendCellMeta;
  const { setValue, control } = useFormContext();
  const sortedOptions = sortDropdownOptions(meta.source.options);

  const additiveValue = useWatch({
    name: `${rowsPropertyName}.${row.index}.additive`,
    defaultValue: '',
  });

  const additiveSelected = meta.source.options.find(
    (option) => option.value.toLowerCase() === additiveValue.toLowerCase()
  );

  const setAdditiveType = useCallback(
    (option: GroupedComboboxTypes.Option) => {
      if (option) {
        const additiveTypeValue = meta.parent.options.find((parent) =>
          parent.relatedKeys.map((r) => r.toLowerCase()).includes(option.value.toLowerCase())
        );
        setValue(`${rowsPropertyName}.${row.index}.additiveType`, additiveTypeValue?.value);
      }
    },
    [additiveSelected]
  );

  // Build the groups from additiveType and additive
  const groups = useMemo(() => {
    return meta.parent.options.map((additiveType) => ({
      text: additiveType.text, // Nombre del grupo
      options: sortedOptions.filter((additive) =>
        additiveType.relatedKeys.map((r) => r.toLowerCase()).includes(additive.value.toLowerCase())
      ), // Filter the additive options
    }));
  }, [meta.parent.options, sortedOptions]);

  // Handle the selection change in the Combobox
  const handleComboboxChange = (selectedOption: GroupedComboboxTypes.Option | null) => {
    if (selectedOption) {
      setAdditiveType(selectedOption);
      setValue(`${rowsPropertyName}.${row.index}.${column.id}`, selectedOption.value);
    }
  };
  return (
    <GroupedCombobox
      rowId={row.index.toString()}
      groups={groups}
      placeholder="Pick one"
      initialSelectedOption={additiveSelected as GroupedComboboxTypes.Option}
      onChange={(selectedItem) => {
        handleComboboxChange(selectedItem);
      }}
    />
  );
};
