import React from 'react';
import { Header } from './Header';
import './app-layout-min.css';
import { Outlet } from 'react-router';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Sidebar } from './Sidebar';
import { Main } from './Main';

type AppLayoutMinProps = {
  children?: React.ReactNode;
  msalInstance: IPublicClientApplication;
};

export const AppLayoutMin = ({ msalInstance, children }: AppLayoutMinProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-1 flex">
          <Sidebar />
          <Main>
            <Outlet />
          </Main>
        </div>
      </div>
    </MsalProvider>
  );
};
