import { DebouncedInput } from '@components/form/DebouncedInput';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  getFilteredRowModel,
  FilterFn,
  ColumnFiltersState,
  Column,
  PaginationState,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Paginator } from '../Paginator';
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/16/solid';

type ExistingExperimentsTableProps<TData extends object> = {
  data: TData[];
  dataToExport?: TData[];
  columns: ColumnDef<TData, any>[];
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  fuzzyFilterDef: FilterFn<TData>;
};

export const ExistingExperimentsTable = <TModel extends object>({
  data,
  dataToExport = data,
  columns,
  globalFilter,
  setGlobalFilter,
  fuzzyFilterDef,
}: ExistingExperimentsTableProps<TModel>): React.ReactElement => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([{ id: 'date', desc: true }]);

  const table = useReactTable<TModel>({
    data: data,
    columns: columns,
    filterFns: {
      fuzzy: fuzzyFilterDef, //define as a filter function that can be used in column definitions
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    enableSortingRemoval: false, // Disable the unsorted state
    enableMultiSort: true, // Enable multi-sort
    maxMultiSortColCount: 2, // Limit to 3 columns for multi-sort
    manualSorting: false, // Set to true if sorting is handled server-side
    sortDescFirst: true, // Sort descending first
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilterDef, //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getPaginationRowModel: getPaginationRowModel(), //client side pagination
    getSortedRowModel: getSortedRowModel(), //client side sorting
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <div>
      <table className="table table-xs border-2 border-separate table-zebra rounded-none">
        <thead className="bg-gray-100">
          {table.getHeaderGroups().map((hg, index) => (
            <tr key={index}>
              {hg.headers.map((header, index) => {
                return (
                  <th
                    key={index}
                    colSpan={header.colSpan}
                    className="px-1 py-1 text-xs font-medium text-gray-900 cursor-pointer"
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          className="flex items-center justify-between"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <BarsArrowUpIcon className="h-4 w-4" />,
                            desc: <BarsArrowDownIcon className="h-4 w-4" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <td key={`${row.id}-${index}`}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator table={table} />
    </div>
  );
};

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();

  return (
    <DebouncedInput
      value={(columnFilterValue ?? '') as string}
      onChange={(value) => column.setFilterValue(value)}
      placeholder={`Search...`}
      className="w-20 font-light border-1"
      sizeProp={'xs'}
    />
  );
}
