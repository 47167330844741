import { useMatch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const useCategoryPath = () => {
  const location = useLocation();

  // Landing Route
  const isLandingVisible = useMatch('/');

  // Technical Services Routes
  const isTicketingRequestRoute = useMatch('/ticketing/request');
  const isRequestUpdateRoute = useMatch('/request/update/:id');
  const isTicketsRoute = useMatch('/ticketing/tickets');
  const isRequestCatalogRoute = useMatch('/request-catalogs');
  const isUsersAndRolesRoute = useMatch('/users-and-roles');
  const isTsSectionVisible = !!(
    isTicketingRequestRoute ||
    isRequestUpdateRoute ||
    isTicketsRoute ||
    isRequestCatalogRoute ||
    isUsersAndRolesRoute
  );

  // R&D Routes
  const isExperimentsRoute = location.pathname.startsWith('/experiments');
  const isCorrosionCatalogRoute = useMatch('/corrosion-catalogs');
  const isTestMethodsRoute = useMatch('/test-methods');
  const isImportDataProjectRoute = useMatch('/import-project-detail-data');
  const isRandDSectionVisible = !!(
    isExperimentsRoute ||
    isCorrosionCatalogRoute ||
    isTestMethodsRoute ||
    isImportDataProjectRoute
  );

  return {
    isLandingVisible,
    isTsSectionVisible,
    isRandDSectionVisible,
  };
};
