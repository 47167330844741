import { Navbar } from 'react-daisyui';
import ProfileControl from './components/profile-control';
import { useIsAuthenticated } from '@azure/msal-react';
import { LogoName } from '@components/svg/logos';
import { Link } from 'react-router-dom';
import { useCategoryPath } from './hooks/useCategoryPath';

export const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { isTsSectionVisible, isRandDSectionVisible } = useCategoryPath();

  return (
    <header className="w-full">
      <Navbar>
        {isAuthenticated && (
          <>
            <Navbar.Start>
              <Link
                to="/"
                className="p-1"
              >
                <LogoName className="h-7 w-52" />
              </Link>
            </Navbar.Start>
            <Navbar.Center>
              <h2 className="text-2xl font-bold mb-2">Stimulation & Intervention Chemistry Technology</h2>
            </Navbar.Center>
            <Navbar.End className="navbar-end">
              <ProfileControl />
            </Navbar.End>
          </>
        )}
      </Navbar>
    </header>
  );
};
