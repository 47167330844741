import { Authorization } from '@lib/Authorization';
import { Menu } from 'react-daisyui';
import { Link, useMatch } from 'react-router-dom';
import { useCategoryPath } from './hooks/useCategoryPath';

export const Sidebar = () => {
  const { isLandingVisible, isTsSectionVisible, isRandDSectionVisible } = useCategoryPath();

  return (
    <>
      {!isLandingVisible && (
        <nav className="order-first w-56 border-2 shrink-0">
          <Menu size="md">
            <Authorization allowedRoles={['Admin']}>
              <Menu.Item>
                <Menu.Title className="text-base text-black">Admin</Menu.Title>
                <Menu>
                  {isTsSectionVisible && (
                    <>
                      <Menu.Item>
                        <Link to="users-and-roles">Users</Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="request-catalogs">TS Request Catalogs</Link>
                      </Menu.Item>{' '}
                    </>
                  )}
                  {isRandDSectionVisible && (
                    <>
                      <Menu.Item>
                        <Link to="corrosion-catalogs">Corrosion Catalogs</Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="import-project-detail-data">Import Data</Link>
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              </Menu.Item>
            </Authorization>
            {isTsSectionVisible && (
              <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Operations', 'Product Mgr/BD']}>
                <Menu.Item>
                  <Menu.Title className="text-base text-black">Technical Services</Menu.Title>
                  <Menu>
                    <Menu.Item>
                      <Link to="ticketing/request">Request</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="ticketing/tickets">Tickets</Link>
                    </Menu.Item>
                  </Menu>
                </Menu.Item>
              </Authorization>
            )}
            {isRandDSectionVisible && (
              <Authorization allowedRoles={['Admin', 'Scientist/Technician', 'Product Mgr/BD']}>
                <Menu.Item>
                  <Menu.Title className="text-base text-black">Experiments</Menu.Title>
                  <Menu>
                    <Menu.Item>
                      <Link to="test-methods">Test Methods</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="experiments/corrosion">Corrosion</Link>
                    </Menu.Item>
                  </Menu>
                </Menu.Item>
              </Authorization>
            )}
          </Menu>
        </nav>
      )}
    </>
  );
};
